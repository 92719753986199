import Link from 'next/link';
import { HeaderItemType, IHeaderItem } from '../interfaces';
import styles from './Menu.module.scss';

interface MenuProps {
  data: IHeaderItem[];
  scrollToSection: (sectionId: HeaderItemType) => void;
}

const Menu = ({ data, scrollToSection }: MenuProps) => (
  <nav className={styles.menu}>
    <ul className={styles.menu__list}>
      {data?.map(item => (
        <li
          className={styles.menu__item}
          key={item.sectionId}
        >
          <button
            type='button'
            className={styles.menu__item__button}
            onClick={() => scrollToSection(item.sectionId)}
          >
            {item.title}
          </button>
        </li>
      ))}
      <li
        className={styles.menu__item}
      >
        <Link
          href='/blog'
          className={styles.menu__item__button}
        >
          Blog
        </Link>
      </li>
    </ul>
  </nav>
);

export default Menu;
