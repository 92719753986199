import Image from 'next/image';
import styles from './SocialLink.module.scss';

interface FooterSocialLinkProps {
  icon: string,
  link: string,
  id: string,
}

const SocialLink = ({ icon, id, link }: FooterSocialLinkProps) => (
  <div className={styles.social}>
    <a
      href={link}
      target='_blank'
      className={styles.social__link}
      rel='noreferrer'
    >
      <Image
        src='/media/socials/grain.svg'
        alt='particles'
        className={styles.social__link__particles}
        width={48}
        height={48}
      />
      <svg className={styles.icon}>
        <use
          xlinkHref={`${icon}#${id}`}
          href={`${icon}#${id}`}
        />
      </svg>
    </a>
  </div>
);

export default SocialLink;
