'use client';

import Link from 'next/link';
import { useEffect, useState } from 'react';

import {
  IFooterUs, IHomepageUsResponse, IServicesUsComponent, IServicesUsHomepage,
} from '@sapientpro/sapientpro-data-models';

import client from '../../apiClient';
import { HomepageSections } from '../../page-components/Home/Home';
import Logo from '../Logo';
import SocialLink from '../SocialLink';
import styles from './Footer.module.scss';

const getFooterData = async () => {
  try {
    return await client.get<IFooterUs>('footer-us', { cache: 'no-cache' });
  } catch (e) {
    console.error(e.message);
    throw new Error(e);
  }
};

const getHomePageServices = async () => {
  try {
    return await client.get<IHomepageUsResponse>('homepage-general?region=us', { cache: 'no-cache' });
  } catch (e) {
    console.error(e.message);
  }
};

const scrollToService = (serviceId: number): void => {
  const anchor: string = `expertise-service-${serviceId}`;
  const section = document.querySelector(`[data-expertise-service-id=${anchor}]`);
  section && section.scrollIntoView({ behavior: 'smooth' });
};

const Footer = () => {
  const [footerData, setFooterData] = useState<IFooterUs | null>(null);
  const [services, setServices] = useState<IServicesUsComponent[]>([]);

  useEffect(() => {
    Promise.all([getFooterData(), getHomePageServices()])
      .then(([footerDetails, homepageData]) => {
        setFooterData(footerDetails);

        const expertiseData: IServicesUsHomepage = homepageData?.content?.find(section => (
          section.__component === HomepageSections.ExpertiseSection
        )) as IServicesUsHomepage;

        expertiseData && setServices(expertiseData.items);
      })
      .catch(console.error);
  }, []);

  return footerData && (
    <footer className={styles.footer}>
      <div className={styles.footer__main}>
        <div className={styles.footer__content}>
          <div className={styles.footer__companyInfo}>
            <Logo
              width={156}
              height={52}
              className={styles.footer__logo}
            />
            <div className={styles.footer__contacts}>
              <div className={styles.footer__nameWrapper}>
                <p className={styles.footer__companyTitle}>{footerData?.title}</p>
              </div>
              <div className={styles.footer__details}>
                <p className={styles.footer__address}>{footerData?.address?.street}</p>
                <p className={styles.footer__city}>{footerData?.address?.city}</p>

                {footerData?.email && (
                  <a
                    className={styles.footer__email}
                    href={`mailto:${footerData.email}`}
                  >
                    {footerData.email}
                  </a>
                )}

                {footerData?.phoneNumber && (
                  <a
                    className={styles.footer__phoneNumber}
                    href={`tel:${footerData.phoneNumber}`}
                  >
                    {footerData.phoneNumber}
                  </a>
                )}

              </div>
            </div>
          </div>
          <div className={styles.services}>
            <p className={styles.services__title}>Services</p>
            <div className={styles.services__content}>
              {services.map(service => (
                <button
                  type='button'
                  className={styles.services__item}
                  key={service.id}
                  aria-label={`scroll to ${service.title}`}
                  onClick={() => scrollToService(service.id)}
                >
                  <p className={styles.services__item__name}>
                    {service.title}
                  </p>
                </button>
              ))}
            </div>
          </div>
          <div className={styles.socialsBlock}>
            {
              footerData?.socials?.telegram && (
                <a
                  target='_blank'
                  href={footerData.socials.telegram}
                  rel='noreferrer'
                  className={styles.footer__telegram}
                >
                  <svg>
                    <use
                      xlinkHref='/media/socials/telegram.svg#telegramSVG'
                      href='/media/socials/telegram.svg#telegramSVG'
                    />
                  </svg>
                  <span>{footerData?.socials?.telegram.replace('http://', '').replace('https://', '')}</span>
                </a>
              )
            }
            <div className={styles.footer__socials}>
              {
                  footerData?.socials?.linkedin && (
                    <SocialLink
                      icon='/media/socials/linkedin.svg'
                      id='linkedinSVG'
                      link={footerData.socials.linkedin}
                    />
                  )
                }
              {
                  footerData?.socials?.instagram && (
                    <SocialLink
                      icon='/media/socials/instagram.svg'
                      id='instagramSVG'
                      link={footerData.socials.instagram}
                    />
                  )
                }
              {
                  footerData?.socials?.facebook && (
                    <SocialLink
                      icon='/media/socials/facebook.svg'
                      id='facebookSVG'
                      link={footerData.socials.facebook}
                    />
                  )
                }
              {
                  footerData?.socials?.youtube && (
                    <SocialLink
                      icon='/media/socials/youtube.svg'
                      id='youtubeSVG'
                      link={footerData.socials.youtube}
                    />
                  )
                }
              {
                  footerData?.socials?.behance && (
                    <SocialLink
                      icon='/media/socials/behance.svg'
                      id='behanceSVG'
                      link={footerData.socials.behance}
                    />
                  )
                }
              {
                  footerData?.socials?.dribbble && (
                    <SocialLink
                      icon='/media/socials/dribble.svg'
                      id='dribbbleSVG'
                      link={footerData.socials.dribbble}
                    />
                  )
                }
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          <p className={styles.copyright__text}>
            {`© ${new Date().getFullYear()} SapientPro. All rights reserved`}
          </p>
          <div className={styles.copyright__rightBlock}>
            <Link
              href='/cookies-policy'
              className={styles.copyright__link}
            >
              Cookies Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
