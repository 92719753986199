import { IMenuItem, ISubMenu } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import Link from 'next/link';
import React, { useState } from 'react';
import styles from './NavMenu.module.scss';

type SubmenuItemProps = {
  submenuItem: ISubMenu | IMenuItem,
};
const SubmenuItem = ({ submenuItem }: SubmenuItemProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  return submenuItem.type === 'submenu' ? (
    <div className={classnames(styles.submenuItem, {
      [styles.active]: isOpened,
    })}
    >
      <div
        className={styles.submenuItemButton}
      >
        <Link
          href='/'
          className={styles.submenuItemButton__link}
        >
          {submenuItem.title}
        </Link>
        <button
          type='button'
          aria-label={`Toggle ${submenuItem.title} category`}
          onClick={() => setIsOpened(!isOpened)}
          className={styles.submenuItemButton__icon}
        >
          <svg>
            <use
              xlinkHref='/media/angleRight.svg#angleSVG'
              href='/media/angleRight.svg#angleSVG'
            />
          </svg>
        </button>
      </div>
      <div className={styles.submenuItemContentWrap}>
        <div className={classnames(styles.submenuItemContent, {
          [styles.active]: isOpened,
        })}
        >
          {(submenuItem as ISubMenu).content.map((submenuSubItem) => (
            <Link
              className={styles.submenuSubItem}
              href={(submenuSubItem as IMenuItem).slug}
            >
              {submenuSubItem.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Link
      href={(submenuItem as IMenuItem).slug}
      className={styles.submenuSubItem}
    >
      {submenuItem.title}
    </Link>
  );
};

export default SubmenuItem;
