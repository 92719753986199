'use client';

import classnames from 'classnames';
import { useSetAtom } from 'jotai';
import Link from 'next/link';

import { useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import useScrollDirection from '../../hooks/useScrollDirection';
import isContactModalOpen from '../../store/contactForm';
import Logo from '../Logo';
import NavMenu from '../NavMenu/NavMenu';
import styles from './Header.module.scss';
import Menu from './Menu';
import { HeaderItemType, IHeaderItem } from './interfaces';

interface HeaderProps {
  withNavigation?: boolean;
}

const headerData: IHeaderItem[] = [
  { title: 'Expertise', sectionId: HeaderItemType.EXPERTISE },
  { title: 'Cases', sectionId: HeaderItemType.CASES },
  { title: 'Team', sectionId: HeaderItemType.TEAM },
  { title: 'Reviews', sectionId: HeaderItemType.REVIEWS },
];

const Header = ({ withNavigation = true }: HeaderProps) => {
  const setContactsModalOpen = useSetAtom(isContactModalOpen);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  const pathname = usePathname();
  const router = useRouter();
  const isHomePage: boolean = pathname === '/';

  const scrollDirection = useScrollDirection();
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => { window.removeEventListener('scroll', handleScroll); };
  }, []);

  const scrollToHomeSection = (sectionId: string, callback?: () => void) => {
    const scrollToSection = () => {
      const section = document.getElementById(sectionId);

      if (section) {
        callback?.();
        setTimeout(() => {
          section.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    };

    if (isHomePage) {
      scrollToSection();
    } else {
      router.push('/');

      let count: number = 1;
      const interval = setInterval(() => {
        count += 1;

        if (window.location.pathname === '/') {
          clearInterval(interval);
          scrollToSection();
        } else if (count > 5) {
          clearInterval(interval);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    setIsMenuOpened(false);
  }, [pathname]);

  useEffect(() => {
    document.documentElement.style.overflowY = isMenuOpened ? 'hidden' : 'unset';
  }, [isMenuOpened]);

  const setRealViewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  useEffect(() => {
    setRealViewportHeight();
    window.addEventListener('resize', setRealViewportHeight);

    return () => {
      window.removeEventListener('resize', setRealViewportHeight);
    };
  }, []);

  return (
    <header
      className={classnames(styles.header, {
        [styles.header_hide]: scrollDirection === 'down',
        [styles.header_show]: scrollDirection === 'up' && scrollPosition > 104,
      })}
    >
      <Link
        passHref
        href='/'
        aria-label='Get back to main page'
        className={styles.logo}
      >
        <Logo
          width={156}
          height={52}
        />
      </Link>

      {withNavigation && (
        <>
          <div className={styles.header__rightBlock}>
            <Menu
              data={headerData}
              scrollToSection={(sectionId: HeaderItemType) => scrollToHomeSection(sectionId)}
            />
          </div>

          <button
            type='button'
            className={styles.contactUsButton}
            onClick={() => setContactsModalOpen(true)}
          >
            Contact us
            <svg className={styles.icon}>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          </button>

          <button
            aria-label='Toggle menu'
            type='button'
            onClick={() => setIsMenuOpened(!isMenuOpened)}
            className={classnames(styles.menuButton)}
          >
            <div className={styles.menuButton__line} />
            <div className={styles.menuButton__line} />
          </button>

          {isMenuOpened && headerData && (
            <NavMenu
              data={headerData}
              scrollToSection={(sectionId: HeaderItemType) => scrollToHomeSection(sectionId, () => setIsMenuOpened(false))}
              onClose={() => setIsMenuOpened(false)}
            />
          )}
        </>
      )}
    </header>
  );
};

export default Header;
