'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useSetAtom } from 'jotai';
import Button, { ButtonVariants } from './Button';
import styles from './CookiesPolicyBanner.module.scss';
import isCookiesAccepted from '../store/cookies';
import { USER_CONSENT_COOKIE_KEY } from '../../env-constants';

const CookiesPolicyBanner = () => {
  const pathname = usePathname();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const setCookiesAccepted = useSetAtom(isCookiesAccepted);

  useEffect(() => {
    const isAskedBefore: boolean = !!localStorage.getItem(USER_CONSENT_COOKIE_KEY);

    if (!isAskedBefore && pathname !== '/cookies-policy') {
      setTimeout(() => setIsModalVisible(true), 5000);
    }
  }, []);

  const acceptCookies = (): void => {
    localStorage.setItem(USER_CONSENT_COOKIE_KEY, '1');
    setCookiesAccepted(true);
    setIsModalVisible(false);
  };

  const rejectCookies = (): void => {
    localStorage.setItem(USER_CONSENT_COOKIE_KEY, '0');
    setCookiesAccepted(false);
    setIsModalVisible(false);
  };

  if (!isModalVisible) {
    return null;
  }

  return (
    <article className={styles.cookiesPolicyBanner}>
      <div className={styles.cookiesPolicyBanner__content}>
        <p className={styles.cookiesPolicyBanner__explanation}>
          This website uses cookies for personalization, analytics, and advertising.
          If you continue to use our website, you consent to our
          {' '}
          <Link
            href='cookies-policy'
            target='_blank'
          >
            Cookies Policy
          </Link>
          .
        </p>
        <div className={styles.cookiesPolicyBanner__actions}>
          <Button
            className={styles.cookiesPolicyBanner__actions__confirmButton}
            variant={ButtonVariants.PRIMARY}
            onClick={acceptCookies}
          >
            Yes
          </Button>
          <Button
            className={styles.cookiesPolicyBanner__actions__rejectButton}
            variant={ButtonVariants.OUTLINED}
            onClick={rejectCookies}
          >
            No
          </Button>
        </div>
      </div>
    </article>
  );
};

export default CookiesPolicyBanner;
