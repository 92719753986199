import { ISubMenu } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { useState } from 'react';
import { MouseParallax } from 'react-just-parallax';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import Ellipse, { EllipseVariants } from '../Ellipse';
import { HeaderItemType, IHeaderItem } from '../Header/interfaces';
import Portal from '../Portal';
import SocialLink from '../SocialLink';
import styles from './NavMenu.module.scss';
import SubmenuItem from './SubmenuItem';

type NavMenuProps = {
  onClose: () => void,
  data: IHeaderItem[],
  scrollToSection: (sectionId: HeaderItemType) => void;
};

const NavMenu = ({
  onClose, data, scrollToSection,
}: NavMenuProps) => {
  const [activeSubmenu, setActiveSubmenu] = useState<null | ISubMenu>(null);
  const [startSubmenuClosing, setStartSubmenuClosing] = useState(false);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);

  const isSmallDevice = (deviceSize <= ScreenSize.TABLET_LANDSCAPE);
  const hideSubmenu = () => {
    setStartSubmenuClosing(true);
    setTimeout(() => {
      setActiveSubmenu(null);
      setStartSubmenuClosing(false);
    }, 510);
  };

  return (
    <Portal className={styles.menu}>
      <aside className={classnames(styles.sidebar, {
        [styles.sidebar_hidden]: activeSubmenu !== null,
        [styles.sidebar_visible]: startSubmenuClosing,
      })}
      >
        <nav className={styles.sidebar__menu}>
          {data.map(menuItem => (
            <button
              type='button'
              key={menuItem.title}
              className={classnames(styles.menuLink)}
              onClick={() => scrollToSection(menuItem.sectionId)}
            >
              {menuItem.title}
            </button>
          ))}
          <Link
            href='/blog'
            className={classnames(styles.menuLink)}
          >
            Blog
          </Link>
        </nav>
        <div className={styles.socials}>
          <SocialLink
            icon='/media/socials/linkedin.svg'
            id='linkedinSVG'
            link='https://www.linkedin.com/company/sapientpro'
          />
          <SocialLink
            icon='/media/socials/instagram.svg'
            id='instagramSVG'
            link='https://www.instagram.com/sapient.pro/'
          />
          <SocialLink
            icon='/media/socials/facebook.svg'
            id='facebookSVG'
            link='https://www.facebook.com/sapientpro'
          />
        </div>
      </aside>
      <div className={classnames(styles.main, {
        [styles.main_visible]: activeSubmenu !== null,
        [styles.main_hidden]: startSubmenuClosing,
      })}
      >
        {!isSmallDevice && (
          <div className={classnames(styles.images, {
            [styles.images_hidden]: activeSubmenu !== null && !startSubmenuClosing,
          })}
          >
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.2}
            >
              <Ellipse
                variant={EllipseVariants.NOISE}
                className={classnames(styles.ellipse, styles.ellipse_noise)}
                size={94}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.3}
            >
              <Ellipse
                variant={EllipseVariants.GRADIENT}
                className={classnames(styles.ellipse, styles.ellipse_gradient)}
                size={40}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.1}
            >
              <Ellipse
                variant={EllipseVariants.BORDER}
                size={24}
                className={classnames(styles.ellipse, styles.ellipse_border)}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.04}
            >
              <Ellipse
                variant={EllipseVariants.DRAW}
                size={600}
                className={classnames(styles.ellipse, styles.ellipse_draw)}
              />
            </MouseParallax>
          </div>
        )}
        <div className={classnames(styles.main__content, {
          [styles.main__content_visible]: activeSubmenu !== null,
          [styles.main__content_hidden]: startSubmenuClosing,
        })}
        >
          {isSmallDevice && (
            <button
              type='button'
              aria-label='Close submenu'
              className={styles.closeSubmenuButton}
              onClick={hideSubmenu}
            >
              back
            </button>
          )}
          {activeSubmenu !== null && (
            <div className={styles.submenu}>
              {isSmallDevice && <p className={classnames(styles.menuLink, styles.menuLink_active)}>{activeSubmenu.title}</p>}
              <div className={styles.submenu__content}>
                <div className={styles.submenu__column}>
                  {activeSubmenu.content?.slice(0, Math.ceil(activeSubmenu.content.length / 2))
                    .map(submenuItem => (
                      <SubmenuItem
                        key={submenuItem.id}
                        submenuItem={submenuItem}
                      />
                    ))}
                </div>
                <div className={styles.submenu__column}>
                  {activeSubmenu.content?.slice(Math.ceil(activeSubmenu.content.length / 2))
                    .map(submenuItem => (
                      <SubmenuItem
                        key={submenuItem.id}
                        submenuItem={submenuItem}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <button
        type='button'
        className={styles.closeButton}
        onClick={onClose}
      >
        <svg>
          <use
            xlinkHref='/media/menuClose.svg#menuCloseSVG'
            href='/media/menuClose.svg#menuCloseSVG'
          />
        </svg>
      </button>
    </Portal>
  );
};

export default NavMenu;
