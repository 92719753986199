'use client';

import { PaletteMode, TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { IContactFormRequestBody } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { MouseParallax } from 'react-just-parallax';
import client from '../../apiClient';
import useDebouncedCallback from '../../hooks/useDebounceCallback';
import isContactModalOpen from '../../store/contactForm';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import { theme } from '../../store/theme';
import AnimatedTitle from '../AnimatedTitle';
import Button, { ButtonVariants } from '../Button';
import Ellipse, { EllipseVariants } from '../Ellipse';
import styles from './ContactForm.module.scss';

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'dark'
      ? {
        primary: {
          main: '#6BD8C7', // --primary-500
        },
      }
      : {
        primary: {
          main: '#3CBFA6', // --primary-900
        },
      }),
  },
});

type Errors = {
  name?: string,
  email?: string,
  service?: string,
  message?: string,
};

enum SubmitStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

const ContactModal = () => {
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const muiTheme = useMemo(() => createTheme(getDesignTokens(appTheme)), [appTheme]);
  const [isModalOpen, setIsModalOpen] = useAtom(isContactModalOpen);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Escape') setIsModalOpen(false);
    };

    document.addEventListener('keydown', handleEscapeKey);

    return function clear() {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflowY = isModalOpen ? 'hidden' : 'auto';
  }, []);

  const [nameValue, setNameValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [messageValue, setMessageValue] = useState<string>('');

  const [isFormMutated, setIsFormMutated] = useState<boolean>(false);
  const isFormFilled = nameValue.length > 0 && emailValue.length > 0 && messageValue.length > 0;
  const [errors, setErrors] = useState<Errors>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus | null>(null);
  const [customServerError, setCustomServerError] = useState<string | null>(null);

  const validateForm = () => {
    setErrors({});
    let currentErrors: Errors = {};

    if (nameValue === '') {
      currentErrors.name = 'Please enter your name';
    } else if (nameValue.length > 70) {
      currentErrors.name = 'Name must be less than 70 characters';
    }

    if (emailValue === '') {
      currentErrors.email = 'Please provide your email address';
    } else if (!/\S+@\S+\.\S+/.test(emailValue)) {
      currentErrors.email = 'Please enter a valid email';
    } else if (emailValue.length > 125) {
      currentErrors.email = 'Email must be less than 125 characters';
    }

    if (messageValue === '') {
      currentErrors.message = 'Please describe your project';
    } else if (messageValue.length > 500) {
      currentErrors.message = 'Message must be less than 500 characters';
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const validateMutatedForm = useDebouncedCallback(() => {
    validateForm();
  }, 300);

  useEffect(() => {
    if (isFormMutated) {
      validateMutatedForm();
    }
  }, [nameValue, emailValue, messageValue, isFormMutated]);

  const submitForm = async (data: IContactFormRequestBody) => {
    setIsLoading(true);
    setSubmitStatus(null);
    setCustomServerError(null);
    try {
      await client.post('contact-form-lead?region=us', { cache: 'no-cache', body: JSON.stringify(data) });
      setSubmitStatus(SubmitStatus.SUCCESS);
      setIsFormMutated(false);
      setNameValue('');
      setEmailValue('');
      setMessageValue('');
    } catch (e) {
      console.error(e.message);
      setSubmitStatus(SubmitStatus.ERROR);
      if (e.response.status === 429) {
        setCustomServerError('Too many attempts, please try again in a minute.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    setIsFormMutated(true);
    const isFormValid = validateForm();

    isFormValid && submitForm({
      name: nameValue,
      email: emailValue,
      message: messageValue,
    });
  };

  return (
    <ThemeProvider theme={muiTheme}>
      {isModalOpen && (
        <div className={styles.contact}>

          <div className={styles.ellipses}>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.04}
            >
              <Ellipse
                variant={EllipseVariants.DRAW}
                size={deviceSize === ScreenSize.MOBILE ? 490 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 770 : 823}
                className={classnames(styles.ellipse, styles.ellipse_draw)}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.17}
            >
              <Ellipse
                variant={EllipseVariants.BORDER}
                size={deviceSize === ScreenSize.MOBILE ? 20 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 31 : 33}
                className={classnames(styles.ellipse, styles.ellipse_border)}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.08}
            >
              <Ellipse
                variant={EllipseVariants.NOISE}
                className={classnames(styles.ellipse, styles.ellipse_noise)}
                size={deviceSize === ScreenSize.MOBILE ? 137 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 215 : 230}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.14}
            >
              <Ellipse
                variant={EllipseVariants.NOISE}
                className={classnames(styles.ellipse, styles.ellipse_noise2)}
                size={deviceSize === ScreenSize.MOBILE ? 77 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 120 : 129}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.22}
            >
              <Ellipse
                variant={EllipseVariants.GRADIENT}
                className={classnames(styles.ellipse, styles.ellipse_gradient)}
                size={deviceSize === ScreenSize.MOBILE ? 30 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 48 : 51}
              />
            </MouseParallax>
            <MouseParallax
              shouldPause={false}
              isAbsolutelyPositioned
              strength={0.22}
            >
              <Ellipse
                variant={EllipseVariants.GRADIENT}
                className={classnames(styles.ellipse, styles.ellipse_gradient2)}
                size={deviceSize === ScreenSize.MOBILE ? 60 : deviceSize === ScreenSize.TABLET_PORTRAIT ? 95 : 101}
              />
            </MouseParallax>
          </div>

          <button
            type='button'
            className={styles.closeButton}
            onClick={() => setIsModalOpen(false)}
          >
            <svg>
              <use
                xlinkHref='/media/menuClose.svg#menuCloseSVG'
                href='/media/menuClose.svg#menuCloseSVG'
              />
            </svg>
          </button>
          <div className={styles.contact__content}>
            <div className={styles.info}>
              <h2 className={styles.title}><AnimatedTitle title='UNLEASH YOUR **IDEA**' /></h2>
              <p className={styles.description}>Feel free to contact us and we will get back to you as soon as we can</p>
            </div>
            <form
              className={styles.form}
              onSubmit={e => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <TextField
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                size='small'
                error={!!errors.name}
                helperText={!!errors.name && errors.name}
                className={styles.input}
                id='nameField'
                label='Enter your name'
                variant='standard'
                sx={{
                  borderColor: 'green',
                }}
              />
              <TextField
                error={!!errors.email}
                helperText={!!errors.email && errors.email}
                value={emailValue}
                onChange={e => setEmailValue(e.target.value)}
                size='small'
                className={styles.input}
                id='emailField'
                label='Enter your email'
                variant='standard'
              />
              <TextField
                error={!!errors.message}
                helperText={!!errors.message && errors.message}
                value={messageValue}
                onChange={e => setMessageValue(e.target.value)}
                id='textareaField'
                label='Tell us about your project'
                multiline
                variant='standard'
                minRows={4}
              />
              <footer className={styles.footer}>
                {submitStatus && (
                  <p className={classnames(styles.status, {
                    [styles.error]: submitStatus === SubmitStatus.ERROR,
                    [styles.success]: submitStatus === SubmitStatus.SUCCESS,
                  })}
                  >
                    {submitStatus === SubmitStatus.ERROR && (customServerError || 'Form submission error. Try again')}
                    {submitStatus === SubmitStatus.SUCCESS && 'The form has been successfully submitted'}
                  </p>
                )}
                <Button
                  type='submit'
                  variant={ButtonVariants.OUTLINED}
                  disabled={!isFormFilled || (isFormMutated && Object.keys(errors).length > 0)}
                  className={styles.sendButton}
                  icon={isLoading && (
                    <img
                      src={`/media/spinner_${appTheme}.webp`}
                      alt=''
                    />
                  )}
                  iconSize={{ width: 16, height: 16 }}
                >
                  Send
                </Button>
              </footer>
            </form>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default ContactModal;
